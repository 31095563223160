
import { Options, Vue } from "vue-class-component";
import auHomeHeader from "./components/home/auHomeHeader.vue";
import { mapState } from "vuex"
import $ from "jquery"

const googleVideoUrl ="//www.douyin.com/aweme/v1/play/?video_id=v0300fg10000c4re9arc77ue96nrrrmg&line=0&file_id=77aa22feb63d43aab3c95ce075f0feaf&sign=6bbb7c962cc2892a3ab042dcfef844cb&is_play_url=1&source=PackSourceEnum_AWEME_DETAIL&aid=6383";
const _360VideoUrl ="//www.douyin.com/aweme/v1/play/?video_id=v0d00fg10000c4re8lbc77uf0un6ffv0&line=0&file_id=e04b60b8069d4ffb96a894d63088fd51&sign=d3672c8787a5c81b3de2f78862557e77&is_play_url=1&source=PackSourceEnum_AWEME_DETAIL&aid=6383";

@Options({
  data() {
    return {
      checkId: "360_jisu",
      list: [
        {
          id: "360_jisu",
          name: "360急速浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/360_jisu.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，页面跳转至360扩展中心",
            },
            {
              title: "2.安装流程",
              desc: "在360扩展中心土豆查工具弹窗内直接点击安装到浏览器即可完成安装。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "chrome",
          name: "谷歌浏览器",
          videoUrl:googleVideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/chrome.png"),
          filename: "tudoucha.zip",
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击浏览器左下角弹窗提示的保留。",
            },
            {
              title: "2.安装流程",
              desc: "点击浏览器右上角设置-更多工具-扩展程序，打开开发者模式，将下载的文件拖拽到扩展程序页面，点击添加扩展程序即可",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "edge",
          name: "Edge浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/edge.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击获取",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击添加扩展即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "qq",
          name: "QQ浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/qq.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，打开插件下载后在所在的文件夹。",
            },
            {
              title: "2.安装流程",
              desc: "进入应用中心，管理我的应用，将插件拖拽到此页面，点击添加扩展程序即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          name: "sougou",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/sougou.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载， 选择直接打开。",
            },
            {
              title: "2.安装流程",
              desc: "选择信任此扩展文件，点击确定安装即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "liebao",
          name: "猎豹浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/liebao.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载。",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击安装即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "2345",
          name: "2345浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/2345.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，打开插件下载后所在的文件夹。",
            },
            {
              title: "2.安装流程",
              desc: "将插件拖拽到浏览器页面，出现土豆查工具弹窗，点击添加即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "360_safe",
          name: "360安全浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/360_safe.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，页面跳转至360扩展中心",
            },
            {
              title: "2.安装流程",
              desc: "在360扩展中心土豆查工具弹窗内直接点击安装到浏览器即可完成安装。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "uc",
          name: "UC浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/uc.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载。 ",
            },
            {
              title: "2.安装流程",
              desc: "出现土豆查工具弹窗，点击添加扩展程序即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "firefox",
          name: "火狐浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/firefox.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击继续安装",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击添加即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
      ],
    };
  },
  computed: {
    browser() {
      return this.list.filter((x: any) => x.id === this.checkId)[0];
    },
    ...mapState({
      top: (state:any) => state.web.top,
    })
  },
  components: {
    auHomeHeader,
  },
  created(){
    $("head").append(`<meta name="referrer" content="no-referrer">`)
  }
})
export default class Home extends Vue {}
