<template>
  <div class="home">
    <div class="auPageCenter">
      <transition name="fadeUp" :duration="3000" appear>
        <div class="fadeUp" v-show="top>=0">
          <h3>工具下载</h3>
          <div class="desc-label">
            土豆查现支持多种浏览器插件安装方式，您可以选择其中一种安装
          </div>
          <div class="desc-label2">
            macOS仅支持
            <span class="text-primary">360极速浏览器</span> 和
            <span class="text-primary">谷歌浏览器</span>
            ，安装方法相同
          </div>
          <div class="desc-label2">
            下载和使用土豆查工具，表示您同意并接受我们的
            <span class="link-label">隐私政策</span>
          </div>
          <!-- <div class="subTitle">
      一键无水印批量下载、评论抓取、流量分析、精准定位、多账户管理。
    </div> -->
          <!-- 默认 -->
          <div class="tuijie">
            <h4 class="title">浏览器插件下载</h4>
            <p class="desc">
              请选择当前默认浏览器并一键下载<br />或选择下方您需要的浏览器
            </p>
            <div class="info">
              <div class="into">
                <img class="img-icon" src="@/assets/icon/chrome.png" />
                <span class="label">谷歌浏览器</span>
                <img
                  class="popover-icon"
                  src="@/assets/img/popover_dangqian.png"
                />
              </div>
              <a
                :href="browser.download"
                :download="browser.filename || 'tudoucha.crx'"
                class="btn"
                >一键下载</a
              >
            </div>
          </div>
          <!-- 浏览器 -->
          <ul class="browsers">
            <li class="item" v-for="(it, index) in list" :key="index">
              <el-image class="icon" :src="it.icon"></el-image>
              <p>{{ it.name }}</p>
              <a :href="it.download" :download="it.filename || 'tudoucha.crx'">
                <i class="el-icon-download"></i>
              </a>
            </li>
          </ul>
          <!-- 没有找到 -->
          <div class="text-center text-gray">
            没有您的浏览器？请点击我
            <a
              :href="browser.download"
              :download="browser.filename || 'tudoucha.crx'"
              class="text-primary cp"
              >下载</a
            >其他版本
          </div>
        </div>
      </transition>
      <!-- 使用教程 -->
      <h3 class="sHeade">使用教程</h3>
      <ul class="browserTabs">
        <li
          class="item"
          :class="{ active: it.id == checkId }"
          v-for="(it, index) in list"
          :key="index"
          @click="checkId = it.id"
        >
          {{ it.name }}
        </li>
      </ul>
    </div>
    <!-- 视频 -->
    <div class="videosPage">
      <div class="videos auPageCenter">
        <div class="left">
          <div class="blank">
            <div class="box" v-for="(it, index) of browser.descs" :key="index">
              <h4>{{ it.title }}</h4>
              <p>{{ it.desc }}</p>
            </div>
          </div>
          <div class="footer">
            <h5>遇到问题？</h5>
            <p>有任何疑问请 <a href="javascript:;">联系客服</a></p>
          </div>
        </div>
        <div class="right">
          <video class="videoPlayer" :src="browser.videoUrl" controls></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import auHomeHeader from "./components/home/auHomeHeader.vue";
import { mapState } from "vuex"
import $ from "jquery"

const googleVideoUrl ="//www.douyin.com/aweme/v1/play/?video_id=v0300fg10000c4re9arc77ue96nrrrmg&line=0&file_id=77aa22feb63d43aab3c95ce075f0feaf&sign=6bbb7c962cc2892a3ab042dcfef844cb&is_play_url=1&source=PackSourceEnum_AWEME_DETAIL&aid=6383";
const _360VideoUrl ="//www.douyin.com/aweme/v1/play/?video_id=v0d00fg10000c4re8lbc77uf0un6ffv0&line=0&file_id=e04b60b8069d4ffb96a894d63088fd51&sign=d3672c8787a5c81b3de2f78862557e77&is_play_url=1&source=PackSourceEnum_AWEME_DETAIL&aid=6383";

@Options({
  data() {
    return {
      checkId: "360_jisu",
      list: [
        {
          id: "360_jisu",
          name: "360急速浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/360_jisu.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，页面跳转至360扩展中心",
            },
            {
              title: "2.安装流程",
              desc: "在360扩展中心土豆查工具弹窗内直接点击安装到浏览器即可完成安装。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "chrome",
          name: "谷歌浏览器",
          videoUrl:googleVideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/chrome.png"),
          filename: "tudoucha.zip",
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击浏览器左下角弹窗提示的保留。",
            },
            {
              title: "2.安装流程",
              desc: "点击浏览器右上角设置-更多工具-扩展程序，打开开发者模式，将下载的文件拖拽到扩展程序页面，点击添加扩展程序即可",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "edge",
          name: "Edge浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/edge.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击获取",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击添加扩展即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "qq",
          name: "QQ浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/qq.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，打开插件下载后在所在的文件夹。",
            },
            {
              title: "2.安装流程",
              desc: "进入应用中心，管理我的应用，将插件拖拽到此页面，点击添加扩展程序即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          name: "sougou",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/sougou.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载， 选择直接打开。",
            },
            {
              title: "2.安装流程",
              desc: "选择信任此扩展文件，点击确定安装即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "liebao",
          name: "猎豹浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/liebao.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载。",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击安装即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "2345",
          name: "2345浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/2345.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，打开插件下载后所在的文件夹。",
            },
            {
              title: "2.安装流程",
              desc: "将插件拖拽到浏览器页面，出现土豆查工具弹窗，点击添加即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "360_safe",
          name: "360安全浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/360_safe.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，页面跳转至360扩展中心",
            },
            {
              title: "2.安装流程",
              desc: "在360扩展中心土豆查工具弹窗内直接点击安装到浏览器即可完成安装。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "uc",
          name: "UC浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/uc.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载。 ",
            },
            {
              title: "2.安装流程",
              desc: "出现土豆查工具弹窗，点击添加扩展程序即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
        {
          id: "firefox",
          name: "火狐浏览器",
          videoUrl: _360VideoUrl,
          download: "/download/tudoucha.crx",
          icon: require("@/assets/icon/firefox.png"),
          descs: [
            {
              title: "1.下载流程",
              desc: "点击一键下载，点击继续安装",
            },
            {
              title: "2.安装流程",
              desc: "页面出现土豆查工具弹窗，点击添加即可。",
            },
            {
              title: "3.使用流程",
              desc: "在“恭喜您，安装成功”页面，选择对应工具“点击体验”即可",
            },
          ],
        },
      ],
    };
  },
  computed: {
    browser() {
      return this.list.filter((x: any) => x.id === this.checkId)[0];
    },
    ...mapState({
      top: (state:any) => state.web.top,
    })
  },
  components: {
    auHomeHeader,
  },
  created(){
    $("head").append(`<meta name="referrer" content="no-referrer">`)
  }
})
export default class Home extends Vue {}
</script>
<style lang="scss" scoped>
.videoPlayer {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #e2e2e2;
}
.right {
  margin-left: 26px;
  flex: 1;
  width: 0;
  height: 520px;
}
.videosPage {
  width: 100%;
  min-width: 1200px;
  padding: 80px 0;
  background: url(../../assets/img/bg_bar.png) no-repeat bottom #fafbfd;
  .videos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .left {
    flex-direction: column;
    width: 324px;
    height: 100%;
    text-align: left;
  }
  .blank {
    background: #fff;
  }
  .box {
    min-height: 104px;
    padding: 16px 20px;
    border-bottom: 1px solid #f0f2f6;
  }
  h4 {
    font-size: 16px;
  }
  p {
    line-height: 22px;
    margin-top: 6px;
    font-size: 14px;
    color: #666;
  }
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    margin-top: 20px;
    background-color: #fff;
    h5 {
      font-size: 16px;
      color: #333;
    }
    a {
      color: #2c6cee;
    }
  }
}
ul {
  list-style: none;
}
.browserTabs {
  width: 100%;
  display: flex;
  line-height: 60px;
  justify-content: space-between;
  border-bottom: 1px solid #eff2f6;
  font-size: 14px;
  color: #3c4254;
  margin-top: 40px;
  li {
    position: relative;
    cursor: pointer;
  }
  .active {
    color: #2c6cee;
    font-size: 16px;
    font-weight: 700;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -2px;
      height: 3px;
      width: 30px;
      background-color: #2c6cee;
      border-radius: 100px;
      transform: translateX(-50%);
    }
  }
}
.sHeade {
  position: relative;
  width: 100%;
  line-height: 76px;
  font-size: 32px;
  text-align: center;
  font-weight: 400;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 46px;
    height: 3px;
    border-radius: 100px;
    background-color: #2c6cee;
  }
}
.text-gray {
  color: #9da0ad;
  margin-top: 20px;
}
.browsers {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 74px;
  .item {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 20%;
    height: 134px;
    transition: all 0.2s;
    border-right: 1px solid #eef0f0;
    border-bottom: 1px solid #eef0f0;
    cursor: pointer;
    box-sizing: border-box;
    p {
      margin-top: 10px;
      color: #3c4254;
      font-size: 14px;
    }
    i {
      display: none;
      color: #2c6cee;
      font-size: 16px;
      font-weight: bold;
    }
    &:nth-of-type(5n),
    &:last-child {
      border-right: 0;
    }
    &:hover {
      background: -o-linear-gradient(top, hsla(0, 0%, 100%, 0), #fff);
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
      box-shadow: 0 12px 16px 0 rgb(69 81 106 / 8%);
      border-bottom: 2px solid #90c5fa;
      i {
        margin-top: 10px;
        display: block;
      }
    }
  }
}
.tuijie {
  z-index: 1;
  width: 442px;
  height: 228px;
  padding: 28px 38px;
  justify-content: space-between;
  box-shadow: 0 7px 6px 0 rgb(131 148 173 / 49%);
  border-radius: 8px;
  border: 2px solid #4f89ff;
  background: url("../../assets/img/bg2.png") no-repeat 100% 0 #e5edfc;
  transition: width 0.2s;
  margin: 40px auto;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  &:after {
    content: "";
    position: absolute;
    left: -4px;
    top: -4px;
    width: 52px;
    height: 55px;
    background: url(../../assets/img/bg_tuijian.png) no-repeat 50%;
    background-size: cover;
  }
  .title {
    position: relative;
    font-size: 20px;
    line-height: 44px;
    font-weight: 700;
    color: #3c4254;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30px;
      height: 3px;
      background: #2c6cee;
      border-radius: 2px;
    }
  }
  .desc {
    margin-top: 24px;
    font-size: 14px;
    line-height: 26px;
    transform: translateY(-10px);
    color: #3c4254;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    .into {
      width: 128px;
      display: flex;
      align-items: center;
      position: relative;
    }
    .label {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 700;
      color: #2d6be7;
    }
    .img-icon {
      width: 28px;
      height: 28px;
    }
    .popover-icon {
      position: absolute;
      right: -54px;
      top: -14px;
      width: 47px;
      height: 21px;
    }
    .btn {
      background-color: #2c6cee;
      color: #fff;
      border-color: #2c6cee;
      width: 120px;
      font-weight: 700;
      box-sizing: border-box;
    }
  }
}
.btn {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  border-radius: 2px;
  background-color: #f6f8fb;
  border: 1px solid #f6f8fb;
  color: #2c6cee;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  height: 40px;
  line-height: 40px;
}
.btnDownload {
  width: 100%;
  margin: 15px 0 15px;
  font-size: 14px;
}
.head {
  justify-content: space-around;
  .hd {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 120px;
    height: 80px;
    position: relative;
    color: rgb(109, 109, 109);
    transition: all 0.3s;
    border-radius: 8px;
    overflow: hidden;
    & > * {
      margin-bottom: 4px;
      &:last-child {
        margin: 0;
      }
    }
    &:hover,
    &.check {
      box-shadow: 0 0 10px rgb(202, 202, 202);
    }
    &:hover .download {
      display: flex;
    }
    .download {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.9);
      display: none;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      color: rgb(109, 109, 109);
      & > * {
        margin-bottom: 8px;
        &:last-child {
          margin: 0;
        }
      }
      i {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .icon {
    width: 32px;
    height: 32px;
  }
}
h3 {
  text-align: center;
  letter-spacing: 6px;
  padding: 45px 0 0;
  font-size: 40px;
  font-weight: 700;
}
.desc-label {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
}
.text-primary {
  color: #2c6cee;
}
.desc-label2 {
  margin-top: 22px;
  font-size: 14px;
  color: #9da0ad;
}
.subTitle {
  color: rgb(160, 160, 160);
  padding: 8px 0 30px;
}
.home {
  //   height: 620px;
  text-align: center;
  // padding: 0 0 30px;
}
.link-label {
  color: #2d6be7;
  text-decoration: underline;
  cursor: pointer;
}
</style>
